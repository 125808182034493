import axios from "axios";
export default {
    namespaced: true,
    state: {
        employee: {}
    },
    getters: {
        employee (state) {
            return state.employee
        }
    },
    mutations: {
        SET_EMPLOYEE (state, value) {
            state.employee = value
        }
    },
    actions: {
        employee ({commit}, id) {
            axios.get('/employee/detail/'+id)
            .then(res => {
                commit('SET_EMPLOYEE', res.data)
            })
        }
    }
}