import router from '../router'
import axios from "axios";
export default {
    namespaced: true,
    state: {
        token: null,
        refreshToken: null,
        user: null,
        company: null,
    },
    getters: {
        token (state) {
            return state.token;
        },
        user (state) {
            return state.user
        },
        company (state) {
            return state.company
        },
    },
    mutations: {
        SET_TOKEN (state, value) {
            state.token = value;
        },
        SET_REFRESH_TOKEN (state, value) {
            state.refreshToken = value;
        },
        SET_USER (state, value) {
            state.user = value;
        },
        SET_COMPANY (state, value) {
            state.company = value
        },
    },
    actions: {
        async login({commit}, credential) {
            await axios.post('/auth/login', credential)
            .then(res => {
                commit('SET_TOKEN', res.data.accessToken);
                commit('SET_REFRESH_TOKEN', res.data.refreshToken);
                commit('SET_USER', res.data.user);
            })
        },
        attempt ({commit}, token) {
           if(token) {
               commit('SET_TOKEN', token);
           }
        },
        async refresh_token({commit}, token) {
            await axios.post('/auth/refresh-token', {
                token: token
            })
            .then(res => {
                commit('SET_TOKEN', res.data.accessToken)
            })
            .catch(() => {
                commit('SET_TOKEN', null)
                commit('SET_REFRESH_TOKEN', null)
                commit('SET_USER', null)
                router.push('/login')
            })
        },
        async company ({commit}) {
            await axios.get('/setting/company')
            .then(res => {
                commit('SET_COMPANY', res.data)
            })
        },
        async user ({commit}) {
            await axios.get('/auth/me')
            .then((res => {
                commit('SET_USER', res.data)
                localStorage.setItem('role',res.data.role )
            }))
        },
        async logout({commit}, token) {
            try {
                await axios.delete('/auth/logout', {
                    data: {
                        token: token
                    }
                })
                .then(() => {
                    commit('SET_TOKEN', null)
                    commit('SET_REFRESH_TOKEN', null)
                    commit('SET_USER', null)
                })
            } catch (er) {
                commit('SET_TOKEN', null)
                commit('SET_REFRESH_TOKEN', null)
                commit('SET_USER', null)
                
            }
        }
    }
}