import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import './store/subscribe'

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

Vue.use(require('vue-pusher'), {
  api_key: '3f69ba6228294488c42c',
  options: {
      cluster: 'ap1',
  }
});

import './index.css'
import './sweetalert.css'
import './animate.min.css'
import './assets/icons/icomoon/styles.min.css'

Vue.config.productionTip = false

axios.interceptors.response.use(
  response => {
    return response
  }, 
  async err => {
    const originalRequiest = err.config
    console.log(err)
    const {response: {status, data}} = err
    
    if(status == 403 && data.message == 'jwt expired') {
      await store.dispatch('auth/refresh_token', localStorage.getItem('refreshToken'))
      originalRequiest.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`
      return axios(originalRequiest)
    }
    if(status == 403 && data.message == 'invalid signature') {
      store.dispatch('auth/logout')
    }
    return Promise.reject(err)
})

store.dispatch('auth/attempt', localStorage.getItem('token'))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
